<template>
 
    <div> 
      <div class=" p-4   ">

        
          <div class="min-w-0 flex-1  text-left">
            
            <h1 class="  text-1xl font-extrabold text-left text-cyan-800">
              Diario y Acumulado
            </h1>
          </div>
          <div class="w-full  ">
        
          <VueApexCharts class="chart-container2"  key="1" v-if="this.seriesDiarias.length >0" ref="demoChart2"    :options="chartOptions" :series="seriesDiarias"></VueApexCharts>
          <SpinnerLoading v-show="this.mostrarSpinner"></SpinnerLoading>
    <!-- {{  this.pos0 }} <br>
      {{ this.lecturasDiarias   }}  --> 
          </div>
        </div>
      

 
      </div>

   
</template>
 
<script>
import { ref } from "vue";
import VueApexCharts from "vue3-apexcharts";
 


import SpinnerLoading from "../SpinnerLoading"

import axios from "axios";

export default {
  props: ['idFaena','aammConsulta', 'label', 'idJaula', 'dias', 'medicionDiaria', 'medicionAcumulada', 'diasMes'],
  emits: ['ampliaGrafico', 'muestraFoto'],
  components: {
    VueApexCharts, SpinnerLoading
  },
  setup() {
    const lecturasDiarias = ref([0]); // Hacer reactivo
    const acumulacionesDiarias = ref([0]);
    const valorMaxY0 = ref(0);
    const valorMinY0 = ref(0);
    const series = ref( [
        {
          name: "Diario",
          type: 'line',
          data:  lecturasDiarias
          //data: ref(this.medicionDiaria)
          //data: [1.1,  1.8,  2.3, 2.7, 3.1, 2.8, 3.0, 2.9, 2.8,   2.6,   2.9,   2.8, 3.1 ]
        },
        {
          name: "Acumulado",
          type: 'area',
          data:  acumulacionesDiarias
         // data: [1.1,  1.8,  2.3, 2.7, 3.1, 2.8, 3.0, 2.9, 2.8,   2.6]
        }
      ]);
    return { lecturasDiarias,acumulacionesDiarias,series, valorMaxY0, valorMinY0 };
  },
  data() {
    return {
      arrayLecturasLocal:[],
      idUnit:0,
      pos0 : '',
      objFaena:[],
      mostrarSpinner: true,
      seriesDiarias:[],
      seriesAcumulaciones:[],
      result_mediciones: true,
      arrayLecturas: [], 
      arrayAcumulaciones: [],
      chartOptions: {
        chart: {
         
          type: 'line',

          toolbar: {
            show: false
          }
        },
        colors: ['#3ca0fc', '#37E6A5'],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth'
        },

        fill: {
          type: 'solid',
          opacity: 0.7,

        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns

          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: this.diasMes,

        },
        yaxis: [
          {
            title: {
              text: 'Kilogramos de oxígeno'
            },
           
            labels: {
              formatter: function (val) {
                return val.toFixed(2);
              },
            }
          },
          {
            opposite: true,
           
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: "#FF1654",
            },
            labels: {
              style: {
                colors: "#FF1654",
              },
              formatter: function (val) {
                return val.toFixed(2);
              },
            },

            title: {

              text: 'Toneladas de oxígeno',

              style: {
                color: "#FF1654",
              },
            },
          },]
      }


    }



  },
  methods: {
    convertirFormatoFecha(fechaOriginal) {
      if (!fechaOriginal) return false;

      const partes = fechaOriginal.split(" ");
      if (partes.length === 3) {
        const dia = partes[0];
        const mes = partes[1];
        const año = partes[2];

        const nuevaFecha = new Date(`${año}-${mes}-${dia}`);

        if (!isNaN(nuevaFecha)) {
          const fechaFormateada = nuevaFecha.toISOString().slice(0, 10);
          return fechaFormateada;
        }
      }

      return null;
    },
    generaSerieFechas(startDate, endDate) {
      const dates = [];
      const currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().substring(0, 10));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return dates;
    },
    getFormattedCurrentDateTime() {
      const now = new Date();
      return this.formatDate(now) + ' ' + this.formatTime(now);
    },
    getFormattedEndOfMonthDateTime() {
      const now = new Date();
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      return this.formatDate(endOfMonth) + ' ' + this.formatTime(endOfMonth);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses van de 0 a 11
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatTime(date) {
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${hours}:${minutes}:${seconds}`;
    },
    propagajaula() {
      this.$emit("muestraFoto", this.idJaula, this.label);
    },

    getdata() {

      if (this.aammConsulta == null) {
        return false;
      }

      let fec = this.aammConsulta.split('-');


      if (!Array.isArray(fec)) {
        alert('no se encontró array aammConsulta')
        return false;

      }
      if (!Array.isArray(this.diasMes)) {
        alert('no se encontró array diasMes')
        return false;
      }

      let f = [`${this.diasMes[0]} ${fec[1]} ${fec[0]}`, `${this.diasMes.length} ${fec[1]} ${fec[0]}`];


      if (f[0] == "" || f[1] == "") return false;
      const startDate = this.convertirFormatoFecha(f[0]); //2023-11-01
      const endDate = this.convertirFormatoFecha(f[1]);





      this.getUnitGasInjection(startDate, endDate, 'N');



    },
    async getUnitGasInjection(startDate, endDate, es_faena_natural) {

 
      let dataFaena = [];
      try {
 
        const apiKey = this.$store.state.login.tokenLogin;
        const objStoreFaenas = this.$store.state.faenas;
        let arrayFaenas = [];
       
        this.objFaena = objStoreFaenas.faenas.filter(faena => faena.id == this.idFaena);
        arrayFaenas = this.objFaena;
         
          // todas las jaulas de la Faena
          let objFaena = arrayFaenas[0];
         
          let jaulas = objFaena.unidades.filter(faena => faena.natural == es_faena_natural);


          // Array de promesas para las solicitudes a axios
          // Si hay N elementos (jaulas) en el array jaulas, entonces se crearán N promesas en total,
          // una para cada elemento del array.
          
          const jaulaPromises = jaulas.map((jaula) => {
            if (jaula.id !== undefined) {
              this.idUnit= jaula.id;
              const requestData = {
                idUnit: jaula.id,
                startDay: `${startDate} 00:00:00`,
                finishDay: `${endDate} 23:59:59`,
              };
              return axios
                .post("/api/ctl/unitGasInjection", requestData, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `${apiKey}`,
                  },
                })
                .then((response) => {

                  
                  let gas = response.data.payload.gas;
              
                  gas.forEach(g => {
                     
                
                  let indice = 0;
                  g.inyection.forEach(med => {

                        
                          let total = 0;
                          if (typeof this.arrayLecturas[indice] !== "undefined") {
                            total = parseFloat(this.arrayLecturas[indice]) || 0;
                          }


                          med = parseFloat(med);
                          
                          
                          this.arrayLecturas[indice] = total + med;
                        

                          // if (indice == 0) console.log("totals", indice, med, lecturas[indice])
                          
                         
                         if (indice==0){
                          this.pos0+= ` ${med} + `;
                      
                        }
                          
                          indice++;
                        


                    });
                });

                  

                })
                .catch((error) => {
                  console.log("Error al obtener datos de la unidad", error);
                  return [];
                });
            }
            return this.arrayLecturas;
          });

        await Promise.allSettled(jaulaPromises);   
          
        

         

        this.arrayAcumulaciones = []
        let acumulado =  0
        for (let c =0 ; c<this.arrayLecturas.length; c++)
        {
            let med = this.arrayLecturas[c];
            acumulado+= med;
            this.arrayAcumulaciones.push(acumulado) ;
            
        
         
         }


        /*  estos son los parametros que debe devolver  */

        this.valorMaxY0=0;
        this.valorMinY0=0;
        // Array Reactivo    =  data []
        this.acumulacionesDiarias = [...this.arrayAcumulaciones]; 

        // Array Reactivo    =  data []
        this.lecturasDiarias = [...this.arrayLecturas]; 
        // data [ ]        =   Array Reactivo 
        this.seriesDiarias = this.series;


 

        this.result_mediciones = true;
        this.mostrarSpinner = false;
      

       
       
  


        return dataFaena;



      } catch (error) {
        console.log("error procesando faenas", error);
      }
    },
    ampliagrafico() {
      //console.log('amplia grafico:', this.idJaula, this.label)
      this.$emit("ampliaGrafico", this.idJaula, this.label);
    }
  },
  created() {

  },
  mounted() {


   

  },
  beforeUnmount() {
    clearInterval(this.polling)

  },
  watch: {
    diasMes: function (valor) {
     
      //    this.chartOptions.xaxis.categories = valor;
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: { categories: valor },


      };
      this.seriesDiarias = [];
      this.pos0 = '';
      this.mostrarSpinner=true;
      this.arrayLecturas = [] ;
      this.arrayLecturasLocal = Array(valor.length -1).fill(0);
      this.getdata()
    },


  }
};
</script>